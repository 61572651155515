<template>
  <HEAD>
    <META http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <META name="description" content="云思shat软件注册版与试用版的区别" />
    <META name="keywords" content="云思shat软件注册版" />
    <META http-equiv="X-UA-Compatible" content="IE=7" />
    <TITLE>云思shat软件注册版与试用版的区别</TITLE>
    <LINK href="css/CSS.css" rel="stylesheet" type="text/css" />
  </HEAD>
  <BODY>
    <DIV id="bdy3"  >
      <DIV id="bdy4">
        <DIV id="head" class="w12">
          <DIV id="homepage"></DIV>

          <DIV style="padding-top: 40px"
            ><strong>全国统一服务热线：17347318127</strong></DIV
          >
        </DIV>

        <DIV id="navv">
          <DIV id="nav">
            <DIV class="nav1"><A href="/dist/index.html">首 页</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav1"><A href="index.html#/price" @click="go_link();">模拟考场</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav1"><A href="/dist/index.html#/download">下载中心</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav3"><A href="/dist/index.html#/price">软件价格</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav1"><A href="/dist/index.html#/sell">购买方式</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav1"><A href="/dist/index.html#/about">联系我们</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
          </DIV>
        </DIV>

        <DIV id="tmain2">
          <DIV id="tm2_1"><img src="../images/32.png" /></DIV>
          <DIV id="cont_left" :style="height">
            <DIV id="tm2_2_1"><H2>客服中心</H2></DIV>
            <DIV class="cont_left_1_6">
              <UL id="m3_02_body">
                <LI id="m3_02_bodyimg2"></LI>

                <LI class="m3_02_bodyfont4">客服小云 QQ:896216279</LI
                ><LI class="m3_02_bodyfont4"
                  ><a
                    target="blank"
                    href="tencent://message/?uin=896216279&Site=软件客服&Menu=yes"
                    ><img
                      src="../ico/pa.gif"
                      alt="896216279"
                      hspace="2"
                      border="0"
                      align="absmiddle" /></a
                ></LI>
                <LI class="m3_02_bodyfont4">客服Cessary QQ:382599652</LI
                ><LI class="m3_02_bodyfont4"
                  ><a
                    target="blank"
                    href="tencent://message/?uin=382599652&Site=软件客服&Menu=yes"
                    ><img
                      src="../ico/pa.gif"
                      alt="382599652"
                      hspace="2"
                      border="0"
                      align="absmiddle" /></a
                ></LI>

                <LI class="m3_02_bodyfont4"> </LI>
                <LI id="m3_02_bodyimg3"></LI>
                <LI class="m3_02_bodyfont2">渠道小云 QQ:896216279</LI>
                <LI class="m3_02_bodyfont4"
                  ><a
                    target="blank"
                    href="tencent://message/?uin=896216279&Site=云思SHAT软件家族&Menu=yes"
                  >
                    <img
                      src="../ico/pa.gif"
                      hspace="2"
                      border="0"
                      align="middle"
                      width="74"
                      height="23" /></a
                ></LI>
                <LI class="m3_02_bodyfont4"> </LI>
                <LI id="m3_02_bodyimg4"></LI>
                <LI class="m3_02_bodyfont2">咨询热线：17347318127 </LI>
                <LI class="m3_02_bodyfont3">投诉：ctaco@foxmail.com</LI>
                <LI class="m3_02_bodyfont3">客服在线时间：周一至周日</LI>
                <DIV style="clear: both"></DIV>
              </UL>
            </DIV>
            <DIV id="tm2_2_3"><img src="../images/bg2.gif" /></DIV>
          </DIV>
          <DIV id="cont_right">
            <DIV id="tm2_3" style="margin-bottom: 15px; height: auto">
              <DIV id="tm2_3_1">
                <DIV id="tm2311" style="width: 237px; height: 47px"
                  ><H2>正式会员与临时会员区别</H2></DIV
                >
              </DIV>

              <DIV class="tm2_3_2" id="show_21" >
                <table border="0" width="97%" cellpadding="0" height="100%" >
                  <tr>
                    <td>&nbsp;</td>
                    <td height="25" width="701">
                      <table
                        style="border-collapse: collapse"
                        borderColor="#83bae9"
                        height="545"
                        cellSpacing="0"
                        cellPadding="0"
                        width="566"
                        align="center"
                        border="1"
                        id="table1"
                      >
                        <tr>
                          <td
                            class="STYLE36"
                            align="middle"
                            bgColor="#dbf1fc"
                            colSpan="3"
                            height="25"
                          >
                            两者区别（使用次数）
                          </td>
                        </tr>
                        <tr>
                          <td align="middle" width="156" height="30">
                            <div align="center">
                              <span class="STYLE17"><b>功能名称</b></span>
                            </div>
                          </td>
                          <td class="STYLE5" align="middle" width="133">
                            <span class="STYLE21">
                              <span class="STYLE27">
                                <img
                                  src="../ico/tm.png"
                                  width="80"
                                  align="absMiddle" /></span
                            ></span>
                          </td>
                          <td class="STYLE5" align="middle" width="133">
                            <span class="STYLE21">
                              <span class="STYLE27">
                                <img
                                  src="../ico/rm.png"
                                  width="80"
                                  align="absMiddle"
                              /></span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="STYLE18" width="156" height="25">
                            <img
                              height="20"
                              src="../gn/cta.png"
                              width="20"
                              style="vertical-align: middle"
                            />
                            <b>云思助手</b>
                          </td>
                          <td align="middle" width="133">
                            <font size="2"><b>50次</b></font>
                          </td>
                          <td align="middle" width="133">
                            <span class="STYLE43">
                              <font size="2"><b>100次起</b></font></span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td class="STYLE18" width="156" height="25">
                            <img
                              height="20"
                              src="../gn/cts.png"
                              width="20"
                              style="vertical-align: middle"
                            />
                            <span style="vertical-align: middle">
                              <b>云思模拟考场</b></span
                            >
                          </td>
                          <td align="middle" width="133">
                            <font size="2"><b>1次</b></font>
                          </td>
                          <td align="middle" width="133">
                            <span class="STYLE43">
                              <font size="2"><b>10次起</b></font></span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td class="STYLE18" height="25">
                            <img
                              height="20"
                              src="../gn/cth.png"
                              width="20"
                              style="vertical-align: middle"
                            />
                            <b>云思互助</b>
                          </td>
                          <td align="middle">
                            <font size="2"><b>1次</b></font>
                          </td>
                          <td align="middle">
                            <span class="STYLE43">
                              <font size="2"><b>无限制</b></font></span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td class="STYLE18" height="25">
                            <img
                              height="20"
                              src="../gn/ctg.png"
                              width="20"
                              style="vertical-align: middle"
                            />
                            <b>云思勾选</b>
                          </td>
                          <td align="middle">
                            <font size="2"><b>NO</b></font>
                          </td>
                          <td align="middle">
                            <span class="STYLE15">
                              <img height="15" src="../ico/y.png" width="15"
                            /></span>
                          </td>
                        </tr>
                        <tr>
                          <td class="STYLE18" width="156" height="25">
                            <img
                              height="8"
                              src="../images/ico14.gif"
                              width="8"
                            />
                            <b>软件免费升级</b>
                          </td>
                          <td align="middle" width="133">
                            <span class="STYLE15">
                              <img height="15" src="../ico/y.png" width="15"
                            /></span>
                          </td>
                          <td align="middle" width="133">
                            <span class="STYLE15">
                              <img height="15" src="../ico/y.png" width="15"
                            /></span>
                          </td>
                        </tr>
                        <tr>
                          <td class="STYLE18" width="156" height="25">
                            <img
                              height="8"
                              src="../images/ico14.gif"
                              width="8"
                            />
                            <b>技术支持</b>
                          </td>
                          <td align="middle" width="133">
                            <span class="STYLE15">
                              <img height="15" src="../ico/y.png" width="15"
                            /></span>
                          </td>
                          <td align="middle" width="133">
                            <span class="STYLE15">
                              <img height="15" src="../ico/y.png" width="15"
                            /></span>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" height="25" class="STYLE18">
                            <img
                              height="8"
                              src="../images/ico14.gif"
                              width="8"
                            /><span class="STYLE17">
                              <font color="#FF603E"><b>使用价格</b></font></span
                            >
                          </td>
                          <td align="middle">
                            <span class="STYLE42"><b>￥0元</b></span>
                          </td>
                          <td align="middle">
                            <span class="STYLE42"><b>￥10元起</b></span>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </DIV>
            </DIV>
          </DIV>

          <DIV id="tm2_4" class="blu" style="overflow: hidden; height: 220px">
            <DIV id="m4_2_new">
              ICP许可证编号：<a href="https://beian.miit.gov.cn" target="_blank"
                >湘ICP备2021008170号-1</a
              >
              © 2021-2025 sellod.cn 版权所有
            </DIV>
          </DIV>
        </DIV>
      </DIV>
    </DIV>
  </BODY>
</template>

<script>
import "../css/CSS.css";
export default {
  data() {
    return {
      height: {
            height: window.innerHeight-200 + 'px'
        },
      height_left: {
          height: window.innerHeight-292 + 'px'
      },
    };
  },
  methods:{
    go_link(){
      var param="width="+window.screen.width * window.devicePixelRatio+",height="+window.screen.height * window.devicePixelRatio+",top=0,left=0,toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no";
      const neww = this.$router.resolve({name: 'Papers', params: {}});
      window.open(neww.href,"云思模拟考场",param);
    }
  }
};
</script>
<style>
#m4_2_new a {
  color: #6b8191;
}
.STYLE36 {
  font-weight: bold;
  color: #0b66a8;
  font-size: 14px;
}
.STYLE17 {
  font-size: 12px;
  color: #333333;
}
.STYLE5 {
  color: #333333;
  font-size: 14px;
}
.STYLE21 {
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}
.STYLE27 {
  color: #333333;
  font-weight: bold;
}
.STYLE37 {
  font-size: 12px;
  color: #535353;
  font-weight: bold;
}
.STYLE18 {
  color: #333333;
  font-size: 12px;
  padding-left: 15px;
  width: auto;
}
.STYLE15 {
  font-size: 12px;
}
.STYLE42 {
  font-size: 12px;
  color: #ff603e;
  font-weight: bold;
}
.STYLE43 {
  font-size: 12px;
  color: #0000ff;
  font-weight: bold;
}
.STYLE19 {
  font-size: 12px;
  color: #ff603e;
  font-weight: bold;
}
</style>
